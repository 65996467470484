import browser from "bowser";
import InvalidBrowser from "./utilities/InvalidBrowser/InvalidBrowser.js";
import { APP_CONFIG } from "./config/appConfig";
import Routes from "./containers/Routes.js";
import { useEffect, useState } from "react";
import useAppState from "./providers/AppProvider.js";
import PromoPopUp from "../scripts/partials/PromoPopUp";
import Sound from "../scripts/partials/Sound";

App.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default function App({ location }) {
  const { byPath, promo, setPromo, byTemplate } = useAppState();
  const image = byTemplate("global")[0].image4 || "";
  const popupMessage = byTemplate("global")[0].h1 || "";

  useEffect(() => {
    pageChangeRoutine();
    setTimeout(hideLoader, APP_CONFIG.loadingDuration);
  }, []);

  useEffect(() => {
    trackPageView(location.pathname);
    pageChangeRoutine();
  }, [location.pathname, location.searchj]);

  const hideLoader = () =>
    (document.querySelector(".site-loader").className +=
      " site-loader--loaded");

  const isValidBrowser = () =>
    !(browser.msie && parseInt(browser.version) < 11);

  const pageChangeRoutine = () => {
    const page = byPath(location.pathname);
    document.title = page.pagetitle || byPath("/").pagetitle;

    window.scrollTo(0, 0);
  };

  const trackPageView = (page) => {
    const { title } = document;
    if (window.ga) {
      ga("send", "pageview", { page, title });
    }
  };

  if (!isValidBrowser()) {
    return <InvalidBrowser />;
  }
  const togglePromo = () => {
    setPromo(false);
  };
  return (
    <div className={`app`}>
      <PromoPopUp
        open={promo}
        close={togglePromo}
        image={image}
        popupMessage={popupMessage}
      />
      <Sound />
      <Routes />
    </div>
  );
}
