import Link from "./Link";
import styles from "../../styles/widgets/_cta.scss";
import backgroundImage from "../../images/button.png";
import { useAppState } from "../providers";

CTA.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

CTA.defaultProps = {
  link: "",
  text: "Continue",
  onClick: () => null,
  className: "",
};

function CTA({ link, text, onClick, className }) {
  const { byTemplate } = useAppState();
  const globalButton = byTemplate("global")[0].image1 || "";
  return (
    <Link
      className={`${className} ${styles.cta}`}
      path={link}
      ariaLabel={text}
      onClick={() => {
        onClick();
      }}
    >
      <div
        className={"background"}
        style={{ backgroundImage: `url(${globalButton})` }}
      >
        <span className={"text"}>{text}</span>
      </div>
    </Link>
  );
}

export default CTA;
