export const calendarArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
  </svg>
);

export const soundOff = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1368.8 1368.4"
    height="25px"
    width="25px"
    fill="#fff"
    stroke="#fff"
  >
    <g id="nBpDfF_00000028282711893596087440000003614948547398029493_">
      <path d="M0 70 70 0l389.2 389.2 274.7-274.7v6.8c0 179.2 0 358.3-.1 537.5 0 4 1.2 6.7 4 9.5 53.9 53.8 107.8 107.6 161.6 161.5 1.4 1.4 2.4 3.2 4.5 5.9 29.3-72.8 37.2-146 23.6-221.3-13.6-75.5-47.1-141.3-97.9-197.8l70.7-70.7c4.4 5 9.6 10.6 14.5 16.4 50.6 59.9 85.8 127.8 104.6 203.9 13.8 55.8 17.7 112.4 11.7 169.6-6.2 59.9-22.9 116.8-49.8 170.6-1.2 2.3-1.6 3.8.6 6 23.2 23 46.3 46.1 69.4 69.2.6.6 1.3 1 2.4 1.9 65.1-114.9 91.2-237.6 75.9-368.5-15.3-130.8-69.8-243.7-158.4-339.8l70.7-70.7c107.7 115.7 172.5 252.4 188.3 410.9 15.8 158.4-20 305.2-103.7 440.2l242.3 242.3-70.4 70.4C866 936 432.9 502.9 0 70zm531.2 388.9 102.3 102.3V356.7c-34.6 34.5-68.9 68.7-102.3 102.2zM343.5 534.9h-59.2v300h5.8c53.2 0 106.3 0 159.5-.1 3.6 0 6.1 1.1 8.7 3.6 57 57.1 114 114.1 171.1 171.2 1.2 1.2 2.6 2.4 4.5 4.2V825.3c1.6 1.4 2.6 2.1 3.5 3 31.2 31.2 62.5 62.4 93.6 93.8 1.6 1.6 2.9 4.5 2.9 6.8.1 107.2.1 214.3.1 321.5v4.8c-2-1.8-3.3-3-4.5-4.2-104.1-104.1-208.1-208.1-312.1-312.2-2.8-2.8-5.6-4-9.5-4-72.3.1-144.7.1-217 .1h-6.4V435.1c1.5-.1 3.1-.2 4.7-.2 17 0 34-.1 51 .1 2.1 0 4.7.8 6.1 2.3 31.9 31.7 63.7 63.5 95.5 95.4.3.4.6.9 1.7 2.2z" />
    </g>
  </svg>
);

export const soundOn = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1049.5 1140.7"
    height="25px"
    width="20px"
    fill="#fff"
    stroke="#fff"
  >
    <path d="M1013.6 778.8c-31.4 100.8-85.5 191.2-160.2 271.5l-70.7-70.7c36.6-39.7 67.4-82.3 92-127.9 16.7-31.6 30.5-63.7 41.3-96.4 11.9-36.8 20.4-75.3 25.1-115.5 2.8-23.5 4.2-46.7 4.3-69.6-.1-22.9-1.5-46.1-4.3-69.6-4.7-40.2-13.1-78.6-25.1-115.5-10.8-32.7-24.6-64.9-41.3-96.4-24.6-45.6-55.4-88.2-92-127.9l70.7-70.7c74.7 80.3 128.8 170.7 160.2 271.5 21.4 67 32.1 136.6 31.8 208.6.2 72-10.5 141.6-31.8 208.6zm-191.2-59.6 1.2-3.9c.5-1.6 1-3.2 1.4-4.8.1-.3.2-.5.2-.8 2.5-8.4 4.7-16.9 6.8-25.5l.3-1.2c.2-.9.4-1.7.6-2.6.3-1.3.6-2.6.9-3.8 7.8-35.2 11.6-70.6 11.6-106.4 0-39.9-4.7-79.4-14.4-118.5-18.8-76.1-54-144-104.6-203.9-4.9-5.8-10.1-11.4-14.5-16.4l-70.7 70.7c50.8 56.5 84.3 122.3 97.9 197.8 8.6 47.5 8.6 94.2.1 140.5l-.1.1c-.8 4.7-1.8 9.4-2.8 14-5 22.3-11.9 44.5-20.8 66.7l.1.1C698 764 673 803 641.3 838.3L712 909c4.4-5 9.6-10.6 14.5-16.4 43.6-51.6 75.7-109.1 95.8-172.7 0-.3 0-.5.1-.7zM545 1135.9v4.8c-2-1.8 1.2-3 0-4.2-104.1-104.1-212.3-208.1-316.2-312.2-2.8-2.8-5.6-4-9.5-4-72.3.1-144.7.1-217 .1h-6.4v-500c1.5-.1 3.1 0 4.7 0 17 0 34-.2 51 0 1.9 0 143.9-1.2 171.5 0C314.6 228.8 454.9 90.1 545 0V1135.9zm-99.7-893.7c-33.6 33.6-142 141.7-178.6 178.3H95.8v300h5.8c53.2 0 106.3 0 159.5-.1 3.6 0 6.1 1.1 8.7 3.6 57 57.1 114 114.1 171.1 171.2 1.2 1.2 2.6 2.4 4.5 4.2-.1-63.2-.1-588.5-.1-657.2z" />
  </svg>
);
