var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (development, hash, htmlWebpackPlugin) {pug_html = pug_html + "\u003C!DOCTYPE html\u003E\u003Chtml lang=\"en\"\u003E\u003Chead\u003E\u003Cmeta charset=\"utf-8\"\u003E\u003Cmeta http-equiv=\"X-UA-Compatible\" content=\"IE=edge,chrome=1\"\u003E\u003Cmeta name=\"viewport\" content=\"width=device-width, initial-scale=1, user-scalable=no\"\u003E\u003Cmeta name=\"robots\" content=\"noindex, nofollow\"\u003E";
if (!(development)) {
}
pug_html = pug_html + "\u003C!--Google tag (gtag.js)--\u003E\u003Cscript async=\"\" src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fgtag\u002Fjs?id=G-Y2ZJ5H6VJN\"\u003E\u003C\u002Fscript\u003E\u003Cscript\u003Ewindow.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-Y2ZJ5H6VJN');\u003C\u002Fscript\u003E\u003C!--meta--\u003E\u003C!--meta--\u003E";
if (!(development)) {
// iterate htmlWebpackPlugin.files.css
;(function(){
  var $$obj = htmlWebpackPlugin.files.css;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var css = $$obj[pug_index0];
pug_html = pug_html + "\u003Clink" + (pug.attr("href", css, true, true)+" rel=\"stylesheet\" type=\"text\u002Fcss\"") + "\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var css = $$obj[pug_index0];
pug_html = pug_html + "\u003Clink" + (pug.attr("href", css, true, true)+" rel=\"stylesheet\" type=\"text\u002Fcss\"") + "\u003E";
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003C\u002Fhead\u003E\u003Cbody bgcolor=\"#130f24\"\u003E\u003C!--seo--\u003E\u003C!--seo--\u003E\u003Cdiv class=\"site-loader\" style=\"background-color:#130f24\"\u003E\u003Cdiv class=\"site-loader__content\"\u003E\u003Cimg src=\"\u002Fsrc\u002Fimages\u002Fpre-loader.gif\" height=\"1920\" width=\"1080\"\u003E\u003Cdiv id=\"rotate\"\u003E\u003C\u002Fdiv\u003E\u003Cp\u003EPlease rotate your device!\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"wrapper\"\u003E\u003C\u002Fdiv\u003E";
if (development) {
pug_html = pug_html + "\u003Cscript" + (pug.attr("src", '/main' + hash + '.js', true, true)+" type=\"text\u002Fjavascript\" async") + "\u003E\u003C\u002Fscript\u003E";
}
else {
// iterate htmlWebpackPlugin.files.js
;(function(){
  var $$obj = htmlWebpackPlugin.files.js;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var js = $$obj[pug_index1];
pug_html = pug_html + "\u003Cscript" + (" type=\"text\u002Fjavascript\""+pug.attr("src", js, true, true)+" async") + "\u003E\u003C\u002Fscript\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var js = $$obj[pug_index1];
pug_html = pug_html + "\u003Cscript" + (" type=\"text\u002Fjavascript\""+pug.attr("src", js, true, true)+" async") + "\u003E\u003C\u002Fscript\u003E";
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003C\u002Fbody\u003E\u003C\u002Fhtml\u003E";}.call(this,"development" in locals_for_with?locals_for_with.development:typeof development!=="undefined"?development:undefined,"hash" in locals_for_with?locals_for_with.hash:typeof hash!=="undefined"?hash:undefined,"htmlWebpackPlugin" in locals_for_with?locals_for_with.htmlWebpackPlugin:typeof htmlWebpackPlugin!=="undefined"?htmlWebpackPlugin:undefined));;return pug_html;};
module.exports = template;