import { soundOff, soundOn } from "../widgets/SVG";
import backgroundMusic from "../../../src/assets/backgroundMusic.wav";
import { _classes } from "../utilities/helpers";
import { useState, useRef } from "react";
import styles from "../../styles/partials/sound.scss";
const cl = _classes(styles);

Sound.propTypes = {};

export default function Sound() {
  const [sound, setSound] = useState(false);
  const audio = new Audio(backgroundMusic);
  const myRef = useRef();
  audio.loop = true;

  const toggleSound = () => {
    if (sound) {
      setSound(false);
      myRef.current.pause();
    } else {
      setSound(true);
      myRef.current.play();
    }
  };

  return (
    <div className={cl("_")}>
      <audio src={backgroundMusic} ref={myRef} loop />
      <button onClick={() => toggleSound()} className={cl("button")}>
        {sound ? soundOn : soundOff}
      </button>
    </div>
  );
}
