import { useEffect, useState } from "react";
import { LazyBoundary } from "react-imported-component";
import { Switch, Route, Redirect } from "react-router-dom";
import useAppState from "../providers/AppProvider";
import ErrorBoundary from "../utilities/ErrorBoundary";
import { AnimatePresence, motion } from "../utilities/FramerMotion";
import viewsPaths from "../utilities/viewPaths";
import Cookies from "js-cookie";
Routes.propTypes = {};

export default function Routes() {
  const { content, location, byTemplate, history } = useAppState();
  const backgroundImage = byTemplate("global")[0].image2;
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const baseRoutes = createBaseRoutes();
    setRoutes(baseRoutes);
    Cookies.get("voted");
    if (Cookies.get("voted") && Cookies.get("confessed")) {
      return history.push(byTemplate("come_back_later")[0].path);
    }
  }, []);

  const createBaseRoutes = () => {
    const donotroute = ["footer", "header"];
    const setPage = (page) => page;

    return Object.keys(content)
      .map((key) => {
        const page = content[key];
        const template = page.template.toLowerCase();
        const Component = viewsPaths[`${template}`] || viewsPaths["default"];
        const formattedPage = setPage(page);

        return (
          !donotroute.includes(page.template) && (
            <Route
              exact
              key={formattedPage.id}
              path={formattedPage.path}
              render={(props) => (
                <LazyBoundary fallback={<div />}>
                  <ErrorBoundary>
                    <Component page={formattedPage} {...props} />
                  </ErrorBoundary>
                </LazyBoundary>
              )}
            />
          )
        );
      })
      .concat(
        <Route
          path="/"
          key="redirectAllElse"
          render={() => <Redirect to="/" />}
        />
      );
  };

  return (
    <main
      style={{
        height: "100vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "contain",
        // overflow: "hidden"
      }}
    >
      <div id="turn">Please rotate your device!</div>
      <div id="container">
        <AnimatePresence exitBeforeEnter>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: "easeInOut", duration: 0.75, delay: 0 }}
            className="page"
            key={location.key}
            layout
          >
            <Switch location={location}>{routes}</Switch>
          </motion.div>
        </AnimatePresence>
      </div>
    </main>
  );
}
