// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sound______1wrwW{position:fixed;top:0;right:0;z-index:1;padding:15px;cursor:pointer}.sound______1wrwW .sound__button___GLIWW{padding:0}", "",{"version":3,"sources":["webpack://./src/styles/partials/sound.scss"],"names":[],"mappings":"AAEA,kBACE,cAAA,CACA,KAAA,CACA,OAAA,CACA,SAAA,CACA,YAAA,CACA,cAAA,CAEA,yCACE,SAAA","sourcesContent":["@import \"../global/parts\";\n\n._ {\n  position: fixed;\n  top: 0;\n  right: 0;\n  z-index: 1;\n  padding: 15px;\n  cursor: pointer;\n\n  .button {\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_": "sound______1wrwW",
	"button": "sound__button___GLIWW"
};
export default ___CSS_LOADER_EXPORT___;
