import Video2 from "../../videos/mark_2.mp4";
import Video3 from "../../videos/mark_3.mp4";
import Video4 from "../../videos/mark_4.mp4";
import Video5 from "../../videos/mark_5.mp4";

export const parse = (json, fallback = false) => {
  try {
    if (json === null || json === "") {
      return fallback;
    }

    return JSON.parse(json) || fallback;
  } catch (e) {
    console.error(e);
    return fallback;
  }
};

// Creates a range (array) of numbers.
export const range = (integer, start = 0) =>
  [...Array(parseInt(integer)).keys()].map((i) => i + parseInt(start));

// Capitalize a string.
export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

// abbreviate class name with a prefix
export const _classes = (styles) => (name) => {
  if (typeof name === "string") {
    return styles[name] || name || "";
  }

  if (Array.isArray(name)) {
    return name.map((n) => styles[n] || n || "").join(" ");
  }

  return "";
};

// Limit how often a function fires.
// Great for event listeners like onResize
// delay in ms
export const debounce = (delay, fn) => {
  let time = Date.now();

  return function debounced(e) {
    if (time + delay - Date.now() < 0) {
      fn(e);
      time = Date.now();
    }
  };
};

//sleep
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const nextPage = (url, time, history) => {
  setTimeout(() => {
    return history.push(url);
  }, time);
};

// Lobby Portrait Functions
export function getQueryParams(url) {
  const paramArr = url.slice(url.indexOf("?") + 1).split("&");
  const params = {};
  paramArr.map((param) => {
    const [key, val] = param.split("=");
    params[key] = decodeURIComponent(val);
  });
  return params;
}

export function getVideo(rating) {
  const videoTable = { 1: null, 2: Video2, 3: Video3, 4: Video4, 5: Video5 };
  return videoTable[rating];
}

// Fisher Yates Shuffle - https://bost.ocks.org/mike/shuffle/
export function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
