import React from "react";
import Modal from "../widgets/Modal";
import Image from "../widgets/Image";
import { _classes, parse, nextPage } from "../utilities/helpers";
import styles from "../../styles/partials/popup.scss";
import useAppState from "../providers/AppProvider"
import CTA from "../widgets/CTA";
const cl = _classes(styles);
PromoPopUp.propTypes = {
  page: PropTypes.object,
  open: PropTypes.bool,
  close: PropTypes.func,
};
export default function PromoPopUp({ popupMessage, open, close, image }) {
  const {history} = useAppState();
  return (
    <Modal visible={open} className="white" image={image}>
      <div className={cl("_")}>
        <div className={cl("flex_container")}>
          <div className={cl("image_wrapper")}>
            <button className={cl("close")} style={{display: "none"}}onClick={close} />
          </div>
          <div className={cl("content")}>{popupMessage}</div>
          <div className={cl("next_buttons")}>
            <div className={cl("yes")} onClick={()=>{nextPage('/confessions',500,history);close()}}>Yes</div>
            <div className={cl("no")} onClick={()=>{nextPage('/step-six',500,history);close()}}>No</div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
